<template>
  <div class="ask_detail">
    <header>
      <div
        class="header_content flex"
        v-loading="loading"
        element-loading-text="拼命加载中"
      >
        <div class="left relative">
          <h1>
            {{ detail.askTitle }}
          </h1>
          <div class="font_length">
            <div class="text" v-if="detail.content">
              {{ excision(detail.content) }}
              <span class="more paddL10" @click="more = true" v-if="more == 0"
                >展开问题描述 <i class="el-icon-arrow-down"></i
              ></span>
            </div>
          </div>
          <div class="more" @click="more = false" v-if="more == 1">
            收起 <i class="el-icon-arrow-up"></i>
          </div>
          <div class="buttons">
            <el-button
              :type="detail.guan ? '' : 'primary'"
              @click="attention(1, '登录后才可以关注')"
              ><i aria-hidden="true" class="el-icon-plus" v-if="!detail.guan"></i
              >{{ detail.guan ? "已关注" : "关注问题" }}
            </el-button>
            <el-button
              type="primary"
              plain
              @click="attention(2, '登录后才可以写回答')"
              ><i aria-hidden="true" class="el-icon-edit"></i>写回答
            </el-button>
          </div>
        </div>
        <div class="right">
          <ul>
            <li>
              <p>回答</p>
              <h4>{{ detail.answerNumber }}</h4>
            </li>
            <li>
              <p>阅读</p>
              <h4>{{ detail.viewNumber }}</h4>
            </li>
          </ul>
        </div>
      </div>
    </header>
    <div class="content flex">
      <div class="ask_left">
        <div class="title">
          全部回答 <span>{{ list.length }}</span>
        </div>
        <ul class="list" v-loading="loadings" element-loading-text="拼命加载中">
          <li class="box" v-for="(n, i) in list" :key="i">
            <div
              class="avatar"
              :class="n.isAnonymity == 0 ? 'cursorP' : ''"
              @click="goRouter('/asklib/askCenter', n.memberId, n.isAnonymity)"
            >
              <img
                :src="n.isAnonymity == 0 ? n.photo : null"
                :alt="n.isAnonymity == 0?n.name:''"
                class="middle err_avatar"
              />
              <span>{{ n.isAnonymity == 0 ? n.name : "匿名用户" }}</span>
            </div>
            <div class="text" v-html="n.content"></div>
            <div class="features flex relative">
              <div
                :class="n.zan ? 'praise' : ''"
                @click="answerDianzan(n.id, n.zan)"
              >
                <p>
                  <img src="~/static/ask/zan.png" alt="点赞" />
                  <img src="~/static/ask/zan_green.png" alt="点赞" class="green" />
                </p>
                {{ n.usefulNumber }}赞
              </div>
              <div @click="commentPageList(n.id), (n.open = !n.open)">
                <p>
                  <img src="~/static/ask/comments.png" alt="评论" />
                  <img
                    src="~/static/ask/comments_green.png"
                    alt="评论"
                    class="green"
                  />
                </p>
                <span>
                  {{ n.open ? "收起评论" : n.countComment + "评论" }}</span
                >
              </div>
              <span class="time">
                {{ n.createDate.slice(0, 10) }}
              </span>
            </div>
            <transition name="el-zoom-in-top">
              <div class="comment transition-box" v-show="n.open">
                <div class="top relative">
                  <div class="login_bar" v-show="childList.login == 0">
                    <el-button type="primary" size="mini" @click="isHide = true"
                      >登录
                    </el-button>
                    后发表评论
                  </div>
                  <el-input
                    type="textarea"
                    v-model="comments.content"
                    rows="5"
                    placeholder="评论这个回答:"
                    resize="none"
                    class="textarea"
                    maxlength="200"
                    show-word-limit
                    v-show="childList.login == 1"
                  ></el-input>
                  <div class="comment_buttons marT20">
                    <el-checkbox v-model="comments.isAnonymity"
                      >匿名</el-checkbox
                    >
                    <el-button
                      type="primary"
                      :disabled="comments.content.length ? false : true"
                      @click="addComment(n.id)"
                      >发布
                    </el-button>
                  </div>
                </div>
                <ul class="list">
                  <li
                    class="box"
                    v-for="(item, index) in childList"
                    :key="index"
                  >
                    <div
                      class="avatar"
                      :class="item.isAnonymity == 0 ? 'cursorP' : ''"
                      @click="
                        goRouter(
                          '/asklib/askCenter',
                          item.memberId,
                          item.isAnonymity
                        )
                      "
                    >
                      <img
                        :src="item.isAnonymity == 0 ? item.photo : null"
                        :alt="item.name"
                        class="middle err_avatar"
                      />
                      <span>{{
                        item.isAnonymity == 0 ? item.name : "匿名用户"
                      }}</span>
                    </div>
                    <div class="text">
                      {{ item.content }}
                    </div>
                    <div class="features flex">
                      <div
                        :class="item.zan ? 'praise' : ''"
                        @click="
                          commentDianzan(item.answerId, item.id, item.zan)
                        "
                      >
                        <p>
                          <img src="~/static/ask/zan.png" alt="" />
                          <img
                            src="~/static/ask/zan_green.png"
                            alt=""
                            class="green"
                          />
                        </p>
                        {{ item.usefulNumber }}赞
                      </div>
                    </div>
                  </li>
                </ul>
              </div>
            </transition>
          </li>
        </ul>
        <div class="pages" v-show="page.total > 10">
          <el-pagination
            background
            class="work_pagination"
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
            :current-page.sync="page.pageNum"
            :page-size="page.pageSize"
            layout="prev, pager, next, total, jumper"
            :total="page.total"
          >
          </el-pagination>
        </div>
      </div>
      <div class="ask_right">
        <div class="top">
          <div class="avatar">
            <img
              :src="detail.isAnonymity == 0 ? detail.askPhoto : ''"
              :alt="detail.askName"
              class="err_avatar"
            />
            <div class="name">
              {{ detail.isAnonymity == 0 ? detail.askName : "匿名用户" }}
            </div>
            <div
              class="url"
              v-if="detail.isAnonymity == 0"
              @click="goRouter('/asklib/askCenter', detail.memberId)"
            >
              <el-button type="primary" plain>进入主页</el-button>
            </div>
          </div>
          <div class="topic" v-for="(n, i) in detailList" :key="i">
            <div class="name">
              {{ n.askTitle }}
            </div>
            <p>
              <span>{{ n.viewNumber }}阅读</span>
              <span>{{ n.answerNumber }}回答</span>
              <span>{{ n.collectNumber }}关注</span>
            </p>
          </div>
        </div>
        <div class="ad relative" v-for="(n, i) in adList" :key="i">
          <a :href="n.picUrl" :title="n.adName">
            <img :src="n.picSrc" :title="n.adName" />
            <!--<div class="type">-->
            <!--课程-->
            <!--</div>-->
          </a>
        </div>
      </div>
    </div>
    <el-dialog
      title=""
      :visible.sync="dialogVisible"
      width="50%"
      :before-close="handleClose"
    >
      <div class="dialog-content">
        <editor2
          placeholder="请输入"
          @html="editChange"
          :testcontent="dialog.content"
          height="400"
        ></editor2>
      </div>
      <div slot="footer" class="dialog-footer">
        <el-checkbox v-model="dialog.isAnonymity">匿名</el-checkbox>
        <el-button
          type="primary"
          @click="addAnswer"
          :disabled="dialog.content.length ? false : true"
          >发布
        </el-button>
      </div>
    </el-dialog>
    <login-prompt
      :goShow="isHide"
      @close="handleClose"
      title="登录"
      :text="loginText"
    ></login-prompt>
  </div>
</template>

<script>
import editor2 from "@/components/public/editor2";
import loginPrompt from "@/components/public/loginPrompt";

export default {
  name: "askDetail",
  metaInfo() {
    return {
      meta: this.metaData,
    };
  },
  components: {
    editor2,
    loginPrompt,
  },
  data() {
    return {
      metaData: [],
      detail: [],
      detailList: [],
      more: 0,
      show: false,
      isHide: false,
      dialog: {
        askId: "",
        isAnonymity: "",
        content: "",
      },
      dialogVisible: false,
      list: [],
      childList: { login: "" },
      adList: [],
      page: {
        askId: "",
        pageNum: 1,
        pageSize: 10,
        total: 0,
      },
      pages: {
        answerId: "",
        pageNum: 1,
        pageSize: 10,
      },
      comment: false,
      comments: {
        answerId: "",
        content: "",
        isAnonymity: "",
      },
      loading: false,
      loadings: false,
      loginText: "登录之后才可以发表评论",
      isLogin: "",
    };
  },
  methods: {
    //问答详情
    async info() {
      this.loading = true;
      await this.$api
        .detailApi({ id: this.$route.query.id })
        .then((res) => {
          this.loading = false;
          if (res.data.success) {
            this.detail = res.data.data.askdetail;
            document.title =

              this.detail.askTitle +

              "-兰州市残疾人就业创业网络服务平台";
            this.metaData.push(
              {
                name: "keywords",
                content:
                  "【" +
                  this.detail.askTitle +
                  "】-兰州市残疾人就业创业网络服务平台",
              },
              {
                name: "description",
                content:
                  this.detail.content +
                  "兰州市残疾人就业创业网络服务平台是中国残联官方的残疾人找工作的网站。。",
              }
            );
            this.detailList = res.data.data.records;
            this.isLogin =
              this.$store.state.userType ||
              JSON.parse(localStorage.getItem("userType"));
            this.answerList(this.$route.query.id);
          }
          this.ad();
        })
        .catch((err) => {
          this.loading = false;
        });
    },
    //回答列表
    answerList(id) {
      this.loadings = true;
      this.page.askId = id || this.$route.query.id;
      this.$api
        .answerPageListApi(this.page)
        .then((res) => {
          this.loadings = false;
          if (res.data.success) {
            this.list = res.data.data.records;
            this.page.total = res.data.data.total;
          }
        })
        .catch((err) => {
          this.loadings = false;
        });
    },
    //广告位
    ad() {
      this.$api
        .adApi({ classId: "40288163734799820173479a4d640001" })
        .then((res) => {
          this.adList = res.data.data;
        });
    },
    //关注
    attention(index, val) {
      if (this.isLogin) {
        if (index == 1) {
          this.$api.detailGuanApi({ askId: this.detail.id }).then((res) => {
            if (res.data.success) {
              this.detail.guan = !this.detail.guan;
              if (this.detail.guan) {
                this.$message.success(res.data.msg);
              } else {
                this.$message.error(res.data.msg);
              }
            }
          });
        } else {
          this.dialogVisible = true;
        }
      } else {
        this.isHide = true;
        this.loginText = val;
      }
    },
    //点赞回答
    answerDianzan(id, zan) {
      if (this.isLogin) {
        this.$api.answerDianzanApi({ answerId: id }).then((res) => {
          if (zan) {
            this.$message.error(res.data.msg);
          } else {
            this.$message.success(res.data.msg);
          }
          this.answerList();
        });
      } else {
        this.isHide = true;
        this.loginText = val;
      }
    },
    //添加回答
    addAnswer() {
      this.dialog.askId = this.$route.query.id;
      if (this.dialog.isAnonymity) {
        this.dialog.isAnonymity = 1;
      } else {
        this.dialog.isAnonymity = 0;
      }
      this.$api
        .addAnswerApi(this.dialog)
        .then((res) => {
          if (res.data.success) {
            this.dialogVisible = false;
            this.$message.success(res.data.msg);
          }
        })
        .catch((err) => {
          this.$message.error("添加失败，请您检查网络!");
        });
    },
    //打开评论列表
    commentPageList(id) {
      this.pages.answerId = id;
      this.$api.commentPageListApi(this.pages).then((res) => {
        this.childList = res.data.data.records;
        if (this.isLogin) {
          this.childList.login = 1;
        } else {
          this.childList.login = 0;
        }
      });
    },
    //添加评论
    addComment(id) {
      this.comments.answerId = id;
      if (this.comments.isAnonymity) {
        this.comments.isAnonymity = 1;
      } else {
        this.comments.isAnonymity = 0;
      }
      this.$api.addCommentApi(this.comments).then((res) => {
        if (res.data.success) {
          this.$message.success(res.data.msg);
          this.comments.content = "";
          this.comments.isAnonymity = false;
        } else {
          this.$message.error(res.data.msg);
        }
      });
    },
    //点赞评论
    commentDianzan(id, ids, zan) {
      this.$api.commentDianzanApi({ id: ids }).then((res) => {
        if (zan) {
          this.$message.error(res.data.msg);
        } else {
          this.$message.success(res.data.msg);
        }
        this.commentPageList(id);
      });
    },
    //去个人中心
    goRouter(url, id, type) {
      if (type == 0 || type == null) {
        const { href } = this.$router.resolve({
          path: url,
          query: {
            id: id,
          },
        });
        window.open(href, "_blank");
      }
    },
    //富文本传参
    editChange(val) {
      this.dialog.content = val;
    },
    //控制字体长度
    excision(text) {
      if (!this.more) {
        if (text.length > 180) {
          return text.slice(0, 180) + "...";
        } else {
          this.more = 3;
        }
      }
      return text;
    },
    handleClose() {
      this.dialogVisible = false;
      this.isHide = false;
    },
    //分页
    handleCurrentChange: function (val) {
      this.page.pageNum = val;
      this.answerList();
    },
    handleSizeChange(val) {
      console.log(`每页 ${val} 条`);
    },
  },
  created() {
    this.info();
    //判断是否移动端
    this.$mobile(
        "https://lzmz.cdpee.org.cn/#/pages/community/question/questionDetail?askId=" +
        this.$route.query.id
    );
  },
  mounted() {
    this.$emit("goRouter", "/asklib/");
  },
};
</script>

<style scoped lang="less">
@import "~assets/css/ask.less";

/deep/ .dialog-footer,
.comment_buttons {
  .el-checkbox {
    margin-right: 20px;

    .el-checkbox__label {
      color: #666666;
    }
  }

  .el-button {
    width: 100px;
  }

  .is-disabled {
    color: #666666;
    background: #f4f4f4;
    border: #f4f4f4 solid 1px;
  }
}

.dialog-footer {
  margin-top: 20px;
}

.comment_buttons {
  text-align: right;

  .is-disabled {
    color: white;
    background: #ccc;
    border: #ccc solid 1px;
  }
}
</style>
